import { useEffect, useRef, useState } from 'react';

export default function Loading(props: { height?: string }) {
    const refContainer = useRef(null as unknown as HTMLDivElement);
    const [posY, setPosY] = useState(0);
    useEffect(() => {
        const container = refContainer.current;
        if (container) {
            setPosY(container.offsetTop);
        }
    }, [refContainer]);

    return (
        <div
            ref={refContainer}
            className="w-full grid place-items-center"
            style={{ height: props.height ? props.height : `calc(100vh - ${posY}px - 1.5rem)` }}>
            <div>
                <div className="breeding-rhombus-spinner mr-3">
                    <div className="rhombus child-1 bg-red-500"></div>
                    <div className="rhombus child-2 bg-red-500"></div>
                    <div className="rhombus child-3 bg-red-500"></div>
                    <div className="rhombus child-4 bg-red-500"></div>
                    <div className="rhombus child-5 bg-red-500"></div>
                    <div className="rhombus child-6 bg-red-500"></div>
                    <div className="rhombus child-7 bg-red-500"></div>
                    <div className="rhombus child-8 bg-red-500"></div>
                    <div className="rhombus big bg-red-500"></div>
                </div>
            </div>
        </div>
    );
}
