import { atom, useRecoilState } from 'recoil';

const connectivityState = atom({
    key: 'connectivity',
    default: true
});

export function useConnectivity() {
    const [connected, setConnected] = useRecoilState(connectivityState);

    return {
        connected,
        setConnected
    };
}
