import React from 'react';
import ReactGA from 'react-ga4';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { createClient, defaultExchanges, subscriptionExchange } from 'urql';
// import { createClient as createWSClient } from 'graphql-ws';

import {
    ApolloClient,
    DefaultOptions,
    InMemoryCache,
    ApolloProvider,
    gql,
    split,
    ApolloLink
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RecoilRoot } from 'recoil';
import Loading from './components/Loading';
import { useConnectivity } from './hooks/useConnectivity';
import { parseJwt } from './helpers';
import { getMainDefinition } from '@apollo/client/utilities';
import fragmentTypes from './fragmentTypes.json';
import { useLogout } from './hooks/useUserData';
import { BrowserRouter } from 'react-router-dom';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export const API_DOMAIN =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_DOMAIN
        : process.env.REACT_APP_FORGED_LIVE
          ? 'api.forged.dev'
          : 'localhost:8000';
export const FRONTEND_DOMAIN =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_DOMAIN : 'localhost:3000';
export const S =
    process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORGED_LIVE ? 's' : '';

export const WS_URL = `ws${S}://${API_DOMAIN}/ws`;
export const API_URL = `http${S}://${API_DOMAIN}`;
export const FRONTEND_URL = `http${S}://${FRONTEND_DOMAIN}`;

async function getNewToken(impersonate?: string) {
    let token = localStorage.getItem('refreshToken');
    if (token) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation refresh($token: String!, $impersonate: UUID) {
                        refresh(token: $token, impersonate: $impersonate)
                    }
                `,
                variables: {
                    token,
                    impersonate
                }
            });
            token = response.data.refresh;
            if (token) {
                sessionStorage.setItem('accessToken', token);
            }
        } catch {}
    }
    return token ? token : null;
}

async function ping() {
    await apolloClient
        .query({
            query: gql`
                query ping {
                    ping
                }
            `,
            fetchPolicy: 'network-only'
        })
        .catch(() => {
            console.log('Ping failed');
        });
}

async function config() {
    await apolloClient
        .query({
            query: gql`
                query config {
                    config {
                        name
                        apiUrl
                        frontendUrl
                    }
                }
            `,
            fetchPolicy: 'network-only'
        })
        .catch(() => {
            console.log('Application config could not be fetched.');
        });
}

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    }
};

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ possibleTypes: fragmentTypes.possibleTypes }),
    defaultOptions: defaultOptions
});

// const wsClient = createWSClient({
//     url: 'ws://localhost/graphql'
// });

// const urqlClient = createClient({
//     url: '/graphql',
//     exchanges: [
//         ...defaultExchanges,
//         subscriptionExchange({
//             forwardSubscription: (operation) => ({
//                 subscribe: (sink) => ({
//                     unsubscribe: wsClient.subscribe(operation, sink)
//                 })
//             })
//         })
//     ]
// });

function Main() {
    const { connected, setConnected } = useConnectivity();
    const logout = useLogout();

    const httpLink = createUploadLink({
        uri: API_URL
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => {
                if (message === 'Forbidden' && operation.operationName === 'currentUser') {
                    logout();
                }
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                );
            });
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            setConnected(false);
        }
    });

    const wsLink = new WebSocketLink({
        uri: WS_URL,
        options: {
            reconnect: true,
            connectionParams: () => {
                const token = sessionStorage.getItem('accessToken');
                return {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : ''
                    }
                };
            }
        }
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink as unknown as undefined
    );

    const connectivityLink = new ApolloLink((operation, forward) => {
        const observable = forward(operation);

        try {
            return observable.map((data) => {
                if (!connected) {
                    config();
                    setConnected(true);
                }
                return data;
            });
        } catch {
            return observable;
        }
    });

    const authLink = setContext(async (request, { headers }) => {
        let token = sessionStorage.getItem('accessToken');

        if (request.operationName !== 'refresh') {
            if (token) {
                // We have an access token. Get a new one if it is expired.
                const jwt = parseJwt(token);
                if (Date.now() >= jwt.exp * 1000) {
                    console.log('refresh 1');
                    token = await getNewToken(jwt.typ.Access?.impersonation_by);
                }
            } else {
                if (request.operationName !== 'refresh') {
                    // We do not have an access token yet, so get a new one.
                    token = await getNewToken();
                }
            }
        }

        return token
            ? {
                  headers: {
                      ...headers,
                      Authorization: token ? `Bearer ${token}` : ''
                  }
              }
            : {
                  headers: {
                      ...headers
                  }
              };
    });

    apolloClient.setLink(authLink.concat(errorLink).concat(connectivityLink).concat(splitLink));

    setInterval(() => {
        ping();
    }, 2000);
    config();

    return (
        <React.StrictMode>
            <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

            <React.Suspense fallback={<Loading />}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ApolloProvider>
            </React.Suspense>
        </React.StrictMode>
    );
}

if (process.env.NODE_ENV === 'production') {
    const TRACKING_ID = 'G-WQRPWKZ4W6';
    ReactGA.initialize(TRACKING_ID);
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <RecoilRoot>
        <Main />
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
