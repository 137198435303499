import React from 'react';
import './App.css';
import useUserData from './hooks/useUserData';
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
// import Metrics from "./components/metrics/Metrics";
import { DeviceMetrics } from './components/metrics/DeviceMetrics';

const Login = React.lazy(() => import('./components/Login'));
const AccountCreate = React.lazy(() => import('./components/account/AccountCreate'));
const MainShell = React.lazy(() => import('./components/MainShell'));
const ProjectCreate = React.lazy(() => import('./components/project/ProjectCreate'));
const ProjectsList = React.lazy(() => import('./components/project/ProjectsList'));
const Notifications = React.lazy(() => import('./components/Notifications'));
const Project = React.lazy(() => import('./components/project/Project'));
const Account = React.lazy(() => import('./components/account/Account'));
const ProjectOverview = React.lazy(() => import('./components/project/ProjectOverview'));
const ProjectSettings = React.lazy(() => import('./components/project/settings/Settings'));
const IntegrationsProjectSettings = React.lazy(
    () => import('./components/project/settings/IntegrationsProjectSettings')
);
const GeneralProjectSettings = React.lazy(
    () => import('./components/project/settings/GeneralProjectSettings')
);
const ProjectChip = React.lazy(() => import('./components/project/chips/ProjectChip'));
const ProjectChipsList = React.lazy(() => import('./components/project/chips/ProjectChipsList'));
const ProjectChipsCreate = React.lazy(() => import('./components/project/chips/ProjectChipCreate'));
const ProjectBinary = React.lazy(() => import('./components/project/binaries/ProjectBinary'));
const ProjectDevicesList = React.lazy(
    () => import('./components/project/devices/ProjectDevicesList')
);
const ProjectBinariesCreate = React.lazy(
    () => import('./components/project/binaries/ProjectBinaryCreate')
);
const ProjectDevice = React.lazy(() => import('./components/project/devices/ProjectDevice'));
const ProjectRequirement = React.lazy(
    () => import('./components/project/requirements/ProjectRequirement')
);
const ProjectRequirementsList = React.lazy(
    () => import('./components/project/requirements/ProjectRequirementsList')
);
const ProjectRequirementCreate = React.lazy(
    () => import('./components/project/requirements/ProjectRequirementCreate')
);
const E404 = React.lazy(() => import('./components/404'));
const Index = React.lazy(() => import('./components/Index'));
const Signup = React.lazy(() => import('./components/signup'));
const ProjectProvisionersList = React.lazy(
    () => import('./components/project/provisioners/ProjectProvisionersList')
);
const ProjectProvisionersCreate = React.lazy(
    () => import('./components/project/provisioners/ProjectProvisionersCreate')
);
const ProjectProvisioner = React.lazy(
    () => import('./components/project/provisioners/ProjectProvisioner')
);
const AccountConfirm = React.lazy(() => import('./components/account/AccountConfirm'));
const FamiliesList = React.lazy(() => import('./components/family/FamiliesList'));
const FamilyCreate = React.lazy(() => import('./components/family/FamilyCreate'));
const Family = React.lazy(() => import('./components/family/Family'));
const ProjectBlockSchemasList = React.lazy(
    () => import('./components/project/block_schema/ProjectBlockSchemasList')
);
const ProjectBlockSchema = React.lazy(
    () => import('./components/project/block_schema/ProjectBlockSchema')
);
const ProjectBlockSchemasCreate = React.lazy(
    () => import('./components/project/block_schema/ProjectBlockSchemasCreate')
);
const Admin = React.lazy(() => import('./components/admin/Admin'));
const Releases = React.lazy(() => import('./components/Releases'));
const ProjectPlaybook = React.lazy(() => import('./components/project/playbook/ProjectPlaybook'));
const RouteChangeTracker = React.lazy(() => import('./components/RouteChangeTracker'));
const Support = React.lazy(() => import('./components/Support'));
const About = React.lazy(() => import('./components/About'));

const GroupsList = React.lazy(() => import('./components/groups/GroupsList'));
const Group = React.lazy(() => import('./components/groups/Group'));
const GroupCreate = React.lazy(() => import('./components/groups/GroupCreate'));

function App() {
    return (
        <>
            <RouteChangeTracker />
            <div className="App flex-grow bg-gray-100 flex items-center justify-center flex-col">
                <Routes>
                    <Route path="/support" element={<Support />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/" element={<Index />} />
                    <Route
                        path="/dashboard"
                        element={
                            <AuthRoute type="private">
                                <MainShell>
                                    <Navigate to="/projects" />
                                </MainShell>
                            </AuthRoute>
                        }
                    />
                    <Route path="/projects" element={<MainShell />}>
                        <Route
                            path="/projects"
                            element={
                                <AuthRoute type="private">
                                    <ProjectsList />
                                </AuthRoute>
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <AuthRoute type="private">
                                    <ProjectCreate />
                                </AuthRoute>
                            }
                        />
                        <Route path=":projectId" element={<Project />}>
                            <Route
                                path="/projects/:projectId"
                                element={
                                    <AuthRoute type="private">
                                        <ProjectOverview />
                                    </AuthRoute>
                                }
                            />
                            <Route
                                path="overview"
                                element={
                                    <AuthRoute type="private">
                                        <ProjectOverview />
                                    </AuthRoute>
                                }
                            />
                            <Route path="blocks" element={<Outlet />}>
                                <Route path="/projects/:projectId/blocks" element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/blocks/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectBlockSchemasList />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/blocks/:blockSchemaId"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectBlockSchema />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="create"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectBlockSchemasCreate />
                                        </AuthRoute>
                                    }
                                />
                            </Route>
                            <Route path="chips" element={<Outlet />}>
                                <Route path="/projects/:projectId/chips" element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/chips/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectChipsList />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/chips/:chipId"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectChip />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="create"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectChipsCreate />
                                        </AuthRoute>
                                    }
                                />
                                <Route
                                    path="/projects/:projectId/chips/:chipId/:binaryId"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectBinary />
                                        </AuthRoute>
                                    }
                                />
                                <Route
                                    path="/projects/:projectId/chips/:chipId/create"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectBinariesCreate />
                                        </AuthRoute>
                                    }
                                />
                            </Route>
                            <Route path="playbook" element={<Outlet />}>
                                <Route path="/projects/:projectId/playbook" element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/playbook/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectPlaybook />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route path="devices" element={<Outlet />}>
                                <Route path="/projects/:projectId/devices" element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/devices/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectDevicesList />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/devices/:deviceId"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectDevice />
                                            </AuthRoute>
                                        }>
                                        <Route
                                            path="/projects/:projectId/devices/:deviceId/runs/:runId"
                                            element={<Outlet />}>
                                            <Route
                                                path="/projects/:projectId/devices/:deviceId/runs/:runId"
                                                element={
                                                    <AuthRoute type="private">
                                                        <ProjectDevice />
                                                    </AuthRoute>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="requirements" element={<Outlet />}>
                                <Route
                                    path="/projects/:projectId/requirements"
                                    element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/requirements/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectRequirementsList />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/requirements/:requirementId"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectRequirement />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="create"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectRequirementCreate />
                                        </AuthRoute>
                                    }
                                />
                            </Route>
                            <Route path="provisioners" element={<Outlet />}>
                                <Route
                                    path="/projects/:projectId/provisioners"
                                    element={<Outlet />}>
                                    <Route
                                        path="/projects/:projectId/provisioners/"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectProvisionersList />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/provisioners/:provisionerId"
                                        element={
                                            <AuthRoute type="private">
                                                <ProjectProvisioner />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="create"
                                    element={
                                        <AuthRoute type="private">
                                            <ProjectProvisionersCreate />
                                        </AuthRoute>
                                    }
                                />
                            </Route>
                            <Route path="settings" element={<Outlet />}>
                                <Route
                                    path="/projects/:projectId/settings"
                                    element={<ProjectSettings />}>
                                    <Route
                                        path="/projects/:projectId/settings/general"
                                        element={
                                            <AuthRoute type="private">
                                                <GeneralProjectSettings />
                                            </AuthRoute>
                                        }
                                    />
                                    <Route
                                        path="/projects/:projectId/settings/integrations"
                                        element={
                                            <AuthRoute type="private">
                                                <IntegrationsProjectSettings />
                                            </AuthRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="/families" element={<MainShell />}>
                        <Route
                            path="/families"
                            element={
                                <AuthRoute type="private">
                                    <FamiliesList />
                                </AuthRoute>
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <AuthRoute type="private">
                                    <FamilyCreate />
                                </AuthRoute>
                            }
                        />

                        <Route
                            path=":familyId"
                            element={
                                <AuthRoute type="private">
                                    <Family />
                                </AuthRoute>
                            }
                        />
                    </Route>
                    <Route path="/account" element={<Outlet />}>
                        <Route
                            path="/account"
                            element={
                                <AuthRoute type="private">
                                    <MainShell>
                                        <Account />
                                    </MainShell>
                                </AuthRoute>
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <AuthRoute type="public">
                                    <AccountCreate />
                                </AuthRoute>
                            }
                        />
                        <Route path="confirm" element={<Outlet />}>
                            <Route
                                path="/account/confirm"
                                element={
                                    <AuthRoute type="public">
                                        <AccountConfirm />
                                    </AuthRoute>
                                }
                            />
                            <Route
                                path=":token"
                                element={
                                    <AuthRoute type="public">
                                        <AccountConfirm />
                                    </AuthRoute>
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        path="/login"
                        element={
                            <AuthRoute type="public">
                                <Login />
                            </AuthRoute>
                        }>
                        <Route
                            path=":provider"
                            element={
                                <AuthRoute type="public">
                                    <Login />
                                </AuthRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="/admin"
                        element={
                            <AuthRoute type="private">
                                <MainShell>
                                    <Admin />
                                </MainShell>
                            </AuthRoute>
                        }
                    />
                    <Route
                        path="/metrics"
                        element={
                            <AuthRoute type="private">
                                <MainShell>
                                    <DeviceMetrics />
                                </MainShell>
                            </AuthRoute>
                        }
                    />
                    <Route
                        path="/releases"
                        element={
                            <AuthRoute type="private">
                                <MainShell>
                                    <Releases />
                                </MainShell>
                            </AuthRoute>
                        }
                    />
                    <Route path="/groups" element={<MainShell />}>
                        <Route
                            path="/groups"
                            element={
                                <AuthRoute type="private">
                                    <GroupsList />
                                </AuthRoute>
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <AuthRoute type="private">
                                    <GroupCreate />
                                </AuthRoute>
                            }
                        />

                        <Route
                            path=":groupId"
                            element={
                                <AuthRoute type="private">
                                    <Group />
                                </AuthRoute>
                            }
                        />
                    </Route>
                    <Route path="*" element={<E404 />} />
                </Routes>
                <Notifications />
            </div>
        </>
    );
}

export function AuthRoute(props: { type: 'private' | 'public'; children: React.ReactNode }) {
    const { userData } = useUserData();
    const location = useLocation();

    if (props.type === 'public' && userData && location.pathname !== '/') {
        return <Navigate to="/dashboard" replace state={{ path: location.pathname }} />;
    }
    if (props.type === 'private' && !userData) {
        return <Navigate to="/login" replace state={{ path: location.pathname }} />;
    }

    return <>{props.children}</>;
}

export default App;
