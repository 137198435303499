import { gql } from '@apollo/client';
import { useAllProjectsStatsQuery } from '../../generated/graphql';
import { useNotifications } from '../../hooks/useNotifications';
import Loading from '../Loading';
import LoadingError from '../LoadingError';
import { ResponsiveBar } from '@nivo/bar';
// import { BoxLegendSvg } from "@nivo/legends";

gql`
    query allProjectsStats {
        projects {
            id
            name
            creationDate
            devices {
                edges {
                    node {
                        id
                        creationDate
                    }
                }
            }
        }
    }
`;

export function DeviceMetrics() {
    const { showNotification } = useNotifications();

    const { data, loading, error } = useAllProjectsStatsQuery({
        onError: ({ graphQLErrors }) => {
            for (const { message } of graphQLErrors) {
                showNotification({
                    type: 'error',
                    title: 'Project data could not be loaded.'
                });
                console.log(message);
            }
        }
    });

    if (error) {
        return <LoadingError />;
    }

    if (loading || !data) {
        return <Loading />;
    }

    const projects = data?.projects ?? [];

    const stats: ({ date: Date; name: string } & { [x: string]: number })[] = [];

    for (const project of projects) {
        const p: string = project.id;
        for (const device of project.devices.edges) {
            const name = project.name;
            const date = new Date(new Date(Date.parse(device.node.creationDate)).toDateString());
            const day = stats.find((e) => e.date.getTime() === date.getTime());
            if (day) {
                day[p] = (day[p] ?? 0) + 1;
            } else {
                const el = {
                    date,
                    name,
                    [p]: 1
                } as { date: Date; name: string } & { [x: string]: number };
                stats.push(el);
            }
        }
    }

    stats.sort((a, b) => a.date.getTime() - b.date.getTime());

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Devices created over time
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Devices created per day and per project
                    </p>
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg width-full h-96">
                            <ResponsiveBar
                                data={stats}
                                keys={projects.map((e) => e.id)}
                                indexBy="date"
                                margin={{ top: 50, right: 20, bottom: 70, left: 50 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'spectral' }}
                                enableLabel={true}
                                isInteractive={true}
                                tooltipLabel={(e) => e.data.name}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 90,
                                    legend: 'date',
                                    legendPosition: 'middle',
                                    legendOffset: 52,
                                    truncateTickAt: 0,
                                    format: (date) => `${date.getMonth() + 1} / ${date.getDate()}`
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'devices',
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                    truncateTickAt: 0
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 1.6]]
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
