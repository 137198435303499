import { gql } from '@apollo/client';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { apolloClient } from '..';
import { parseJwt } from '../helpers';

function getRefreshToken() {
    const tokenString = localStorage.getItem('refreshToken');
    const userToken = tokenString ? tokenString : null;
    return userToken;
}

function getAccessToken() {
    const tokenString = sessionStorage.getItem('accessToken');
    const userToken = tokenString ? tokenString : null;
    return userToken;
}

const refreshTokenState = atom({
    key: 'refreshToken',
    default: getRefreshToken()
});

const accessTokenState = atom({
    key: 'accessToken',
    default: getAccessToken()
});

const userDataQuery = selector({
    key: 'userData',
    get: async ({ get }) => {
        const token = get(refreshTokenState);
        if (token) {
            try {
                const response = await apolloClient.query({
                    query: gql`
                        query currentUser {
                            currentUser {
                                id
                                admin
                                email
                            }
                        }
                    `
                });
                const jwt = parseJwt(token);
                const accessToken = get(accessTokenState);
                const ajwt = accessToken ? parseJwt(accessToken) : undefined;
                return {
                    email: response.data.currentUser.email,
                    userId: jwt.sub,
                    admin: response.data.currentUser.admin,
                    impersonation: ajwt?.typ.Access?.impersonation_by
                };
            } catch {
                return null;
            }
        } else {
            return null;
        }
    }
});

export default function useUserData() {
    const userData = useRecoilValue(userDataQuery);
    const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
    const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

    const saveRefreshToken = (token?: string) => {
        if (!token) {
            localStorage.removeItem('refreshToken');
            setRefreshToken(null);
        } else {
            localStorage.setItem('refreshToken', token);
            setRefreshToken(token);
        }
    };

    const saveAccessToken = (token?: string) => {
        if (!token) {
            sessionStorage.removeItem('accessToken');
            setAccessToken(null);
        } else {
            sessionStorage.setItem('accessToken', token);
            setAccessToken(token);
        }
    };

    const logout = () => {
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('accessToken');
        apolloClient.resetStore();
        setRefreshToken(null);
        setAccessToken(null);
    };

    return {
        setRefreshToken: saveRefreshToken,
        setAccessToken: saveAccessToken,
        userData,
        refreshToken,
        accessToken,
        logout
    };
}

export function useLogout() {
    const [, setRefreshToken] = useRecoilState(refreshTokenState);
    const [, setAccessToken] = useRecoilState(accessTokenState);

    const logout = () => {
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('accessToken');
        apolloClient.resetStore();
        setRefreshToken(null);
        setAccessToken(null);
    };

    return logout;
}
