import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';

export default function LoadingError(props: { description?: string }) {
    const refContainer = useRef(null as unknown as HTMLDivElement);
    const [posY, setPosY] = useState(0);
    useEffect(() => {
        const container = refContainer.current;
        if (container) {
            setPosY(container.offsetTop);
        }
    }, [refContainer]);

    return (
        <div
            ref={refContainer}
            className="w-full grid place-items-center"
            style={{ height: `calc(100vh - ${posY}px - 1.5rem)` }}>
            <div className="grid place-items-center">
                <ExclamationTriangleIcon className="h-24 w-24 text-red-500 animate-pulse" />
                <div className="text-red-500">
                    {props.description ? props.description : 'The data could not be loaded.'}
                </div>
            </div>
        </div>
    );
}
