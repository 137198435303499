import { atom, useRecoilState } from 'recoil';

export type NotificationInfo = {
    title: string;
    type: 'error' | 'warn' | 'info' | 'success';
    timeout?: number;
    id?: number;
    handle?: ReturnType<typeof setTimeout>;
};

const notificationState = atom({
    key: 'notifications',
    default: [] as NotificationInfo[]
});

export function useNotifications() {
    const [notifications, setNotification] = useRecoilState(notificationState);

    const removeNotification = (notification: NotificationInfo) => {
        if (notification.handle) {
            clearTimeout(notification.handle);
        }
        setNotification(notifications.filter((e) => e.id !== notification.id));
    };

    const showNotification = (notification: NotificationInfo) => {
        const id = Date.now() + Math.trunc(Math.random() * 1000000);
        notification.id = id;

        const handle = setTimeout(() => {
            removeNotification(notification);
        }, notification.timeout ?? 5000);

        notification.handle = handle;

        setNotification([...notifications, notification]);
    };

    return {
        removeNotification,
        showNotification,
        notifications
    };
}
